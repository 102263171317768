var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'nut-stepper': !_vm.simple, 'nut-stepper-simple': _vm.simple }},[_c('span',{class:{ 'nut-stepper-grey': _vm.isGray || _vm.disabled },domProps:{"innerHTML":_vm._s(require('../../assets/svg/minus.svg'))},on:{"click":function($event){return _vm.reduce()}}}),_vm._v(" "),_c('input',{style:({ visibility: _vm.showNum ? 'visible' : 'hidden' }),attrs:{"type":"number","min":_vm.minNum,"max":_vm.max,"disabled":_vm.disabled,"readonly":_vm.readonly || !_vm.isLegal},domProps:{"value":_vm._f("maxv")(_vm.num,_vm.minNum, _vm.max)},on:{"input":_vm.numchange,"keyup":_vm.checknum,"focus":_vm.focus,"blur":_vm.blur}}),_vm._v(" "),_c('div',{class:['nut-stepper-fake', _vm.showAddAnim ? 'nut-stepper-transition' : 'nut-stepper-none-transition'],style:({
      visibility: _vm.showAddAnim ? 'visible' : 'hidden',
      transform: 'translate(0,' + _vm.animTranslate_add + '%)',
      '-ms-transform': 'translate(0,' + _vm.animTranslate_add + '%)',
      '-moz-transform': 'translate(0,' + _vm.animTranslate_add + '%)',
      '-webkit-transform': 'translate(0,' + _vm.animTranslate_add + '%)',
      '-o-transform': 'translate(0,' + _vm.animTranslate_add + '%)'
    })},[_c('div',[_vm._v(_vm._s(_vm.animNum[0]))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.animNum[1]))])]),_vm._v(" "),_c('div',{class:['nut-stepper-fake-', _vm.showReduceAnim ? 'nut-stepper-transition' : 'nut-stepper-none-transition'],style:({
      visibility: _vm.showReduceAnim ? 'visible' : 'hidden',
      transform: 'translate(0,' + _vm.animTranslate_ + '%)',
      '-ms-transform': 'translate(0,' + _vm.animTranslate_ + '%)',
      '-moz-transform': 'translate(0,' + _vm.animTranslate_ + '%)',
      '-webkit-transform': 'translate(0,' + _vm.animTranslate_ + '%)',
      '-o-transform': 'translate(0,' + _vm.animTranslate_ + '%)'
    })},[_c('div',[_vm._v(_vm._s(_vm.animNum[0]))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.animNum[1]))])]),_vm._v(" "),_c('span',{class:{ 'nut-stepper-grey': (_vm.max && Number(_vm.num) > _vm.max - _vm.step) || !_vm.isLegal || _vm.disabled },domProps:{"innerHTML":_vm._s(require('../../assets/svg/plus.svg'))},on:{"click":function($event){return _vm.add()}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }