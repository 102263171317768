var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"vueSkeletonCircle",staticClass:"vue-skeleton-circle",style:({
    backgroundColor: _vm.backColor,
    width: _vm.diameter,
    height: _vm.diameter,
    'border-radius': '50%',
    margin: _vm.margin
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }