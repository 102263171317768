var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"toastfade"}},[(_vm.visible)?_c('div',{class:_vm.toastBodyClass,style:({
      bottom: _vm.center ? 'auto' : _vm.bottom + 'px',
      'background-color': _vm.coverColor,
    }),attrs:{"id":_vm.id},on:{"click":_vm.clickCover}},[_c('div',{staticClass:"nut-toast-inner",class:_vm.toastClass,style:({
        'text-align': _vm.textAlignCenter ? 'center' : 'left',
        'background-color': _vm.bgColor,
        ..._vm.toastStyle,
      })},[(_vm.hasIcon)?_c('span',{staticClass:"nut-toast-icon-wrapper"},[_c('i',{class:['nut-toast-icon', _vm.type, { 'nut-toast-icon-rotate': _vm.type === 'loading' && _vm.loadingRotate }],style:({ 'background-image': _vm.cusIcon })})]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"nut-toast-text",domProps:{"innerHTML":_vm._s(_vm.msg)}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }