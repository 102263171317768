var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nut-swiper",class:[_vm.direction, { dragging: _vm.dragging }],on:{"touchstart":function($event){return _vm._onTouchStart($event)},"mousedown":function($event){return _vm._onTouchStart($event)}}},[_c('div',{staticClass:"nut-swiper-wrap",style:({
      transform: 'translate3d(' + _vm.translateX + 'px,' + _vm.translateY + 'px,0)',
      'transition-duration': _vm.transitionDuration + 'ms',
      '-webkit-transform': 'translate3d(' + _vm.translateX + 'px,' + _vm.translateY + 'px,0)',
      '-webkit-transition-duration': _vm.transitionDuration + 'ms',
      'transition-timing-function': 'ease'
    }),on:{"transitionend":_vm._onTransitionEnd}},[_vm._t("default")],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.paginationVisible),expression:"paginationVisible"}],staticClass:"nut-swiper-pagination"},_vm._l((_vm.slideEls),function(slide,index){return _c('span',{key:index,staticClass:"swiper-pagination-bullet",class:{ active: index + 1 === _vm.currentPage },on:{"click":function($event){_vm.paginationClickable && _vm.setPage(index + 1, true)}}})}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }